exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-central-tsx": () => import("./../../../src/pages/central.tsx" /* webpackChunkName: "component---src-pages-central-tsx" */),
  "component---src-pages-detalle-tsx": () => import("./../../../src/pages/detalle.tsx" /* webpackChunkName: "component---src-pages-detalle-tsx" */),
  "component---src-pages-documentos-tsx": () => import("./../../../src/pages/documentos.tsx" /* webpackChunkName: "component---src-pages-documentos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nivel-central-tsx": () => import("./../../../src/pages/nivelCentral.tsx" /* webpackChunkName: "component---src-pages-nivel-central-tsx" */),
  "component---src-pages-regional-tsx": () => import("./../../../src/pages/regional.tsx" /* webpackChunkName: "component---src-pages-regional-tsx" */),
  "component---src-pages-transparencia-tsx": () => import("./../../../src/pages/transparencia.tsx" /* webpackChunkName: "component---src-pages-transparencia-tsx" */)
}

